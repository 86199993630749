exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-hammerflux-js": () => import("./../../../src/pages/hammerflux.js" /* webpackChunkName: "component---src-pages-hammerflux-js" */),
  "component---src-pages-image-annotation-js": () => import("./../../../src/pages/image-annotation.js" /* webpackChunkName: "component---src-pages-image-annotation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-tokopedia-slash-price-js": () => import("./../../../src/pages/tokopedia-slash-price.js" /* webpackChunkName: "component---src-pages-tokopedia-slash-price-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-visual-gallery-js": () => import("./../../../src/pages/visual-gallery.js" /* webpackChunkName: "component---src-pages-visual-gallery-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

